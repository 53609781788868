import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import PopEmail from 'components/popAlert/PopEmail';
import useModalWrapper from 'components/modal/hooks/useModalWrapper';
import { call } from 'assets/images/common';

import 'assets/css/layouts/footer.scss';
import { footer_instagram_logo, footer_kakaoTalk_logo, footer_youtube_logo } from 'assets/images/main';

// footer.js

function Footer(props) {
    const [ url, setUrl] = useState('');
    const emailModalHandler = useModalWrapper();

    const handleChange = (event) => {
        setUrl(event.target.value);
    };

    const inquiryClick = useCallback((e) => {
        window.ChannelIO('showMessenger');
        // emailModalHandler.handleOpen(e);
    }, []);

    const otherInquiryClick = useCallback((e) => {
        // window.ChannelIO('showMessenger');
        emailModalHandler.handleOpen(e);
    }, []);
    

    if(location.pathname == '/gatePage') return null

    return (
        <>
            <div className="footer">
                <div className="footer-inner">
                    <div className="footer-inner-top">
                        <ul className="footer-term-wrapper">
                            <li className="footer-term">
                                <Link to="/policy/refund">환불 규정</Link>
                                {/* 2022-07-21 텍스트 수정 */}
                            </li>
                            <li className="footer-term">
                                <Link to="/policy/terms">서비스 이용약관</Link>
                            </li>
                            <li className="footer-term">
                                <Link to="/policy/personal" className="bold-footer">
                                개인정보 처리방침
                                </Link>
                                {/* //2022-07-27 스타일 수정 위해 클래스 bold-footer 추가 */}
                            </li>
                            <li className="footer-term">
                                {/* <Link to="/inquiry/group">문의하기</Link> */}
                                <Link to="#" onClick={inquiryClick}>
                                문의하기
                                </Link>
                            </li>
                            <li className="footer-term">
                                <Link to="/apply">선생님 지원하기</Link>
                            </li>
                            <li className="footer-term">
                                {/* <Link to="/inquiry/cooperation">제휴/협력문의</Link> */}

                                {/* <Link to="" onClick={()=>{

                                }}>제휴/협력문의</Link> */}
                                <Link to="#" onClick={otherInquiryClick}>
                                제휴/협력문의
                                </Link>
                            </li>
                            {/* 2022-07-13 추가*/}
                            <li className="footer-term">
                                {/* <Link to="/inquiry/group">단체 교육 문의</Link> */}
                                <Link to="#" onClick={otherInquiryClick}>
                                단체 교육 문의
                                </Link>
                            </li>
                            {/* 2022-07-13 끝*/}
                            
                            {/* //2022-07-21 끝*/}

                            {/* 2023-06-05 공지사항 페이지 추가 */}
                            <li className="footer-term">
                                <Link to="/footer/notice">공지사항</Link>
                            </li>
                            {/* //2023-06-05 끝*/}
                        </ul>
                    </div>
                    <div className="footer-inner-bottom">
                        <div className='footer-inner-bottom-logo'>
                            <img src={footer_youtube_logo} onClick={()=>window.open('https://www.youtube.com/channel/UC16optyNvBdqxSDqQvybpdQ')} />
                            <img src={footer_instagram_logo} onClick={()=>window.open('https://www.instagram.com/liveall_official/')} />
                            <img src={footer_kakaoTalk_logo} onClick={()=>window.open('https://pf.kakao.com/_xlSVxgb')} />
                        </div>
                        <Typography variant="body1">
                            <span><img src={call} alt="전화기" /> 고객센터 1577-1095 / 월~금(공휴일 제외) 13:00~22:00 (저녁시간 : 16:00~17:00)</span><br /> 
                            <span className="line">(주)웅진씽크빅</span>
                            <span className="line">대표자명 : 이봉주</span>
                            <span className="line">사업자등록번호 141-81-09131</span>
                            {/* 2022-07-21 삭제 <br className="onlyMo" /> */}
                            <span>통신판매업신고 : 경기파주-1045</span><br />
                            {/* <br className="onlyPc" /> */}
                            <span className="line">경기도 파주시 회동길 20 (우) 10881</span>
                            {/* <br className="onlyMo" /> */}
                            <span>개인정보보호책임자 : 경영지원실 이수종 실장</span><br />
                            COPYRIGHT ⓒ WOONGJIN THINKBIG. ALL RIGHTS RESERVED
                        </Typography>
                        {/* 
                        2022-07-21 삭제 -> 채널톡에서 상담
                        <div>
                            <Typography variant="h5">1599-1095</Typography>
                            <Typography variant="body2">
                            월-금(공휴일 제외) 09:00 - 18:00
                            </Typography>
                        </div> 
                        */}
                    </div>
                </div>
            </div>

            {emailModalHandler.visibleModal && (
                <PopEmail handleClose={emailModalHandler.handleClose} />
            )}
        </>
    );
}
export default React.memo(Footer);
