import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useCommonMutation, useCommonQuery } from "common/hooks";
import { useApiContext } from "common/context";
import usePopAlert from "components/popAlert/hooks/usePopAlert";
import { useLoginContext } from "common/context/MemberContext";
import { useRadioGroup, useDatePicker } from "common/hooks";
import { useNavigate } from "react-router";
import useBasicPagination from "components/utils/hooks/useBasicPagination";
import moment from "moment";

function useClassInquiry({ classSeq, teacherSeq }) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const [inquires, setInquires] = useState([]);
  const [orderUser, setOrderUser] = useState();
  const paginationInput = useBasicPagination(inquires.length, 10);
  const { isLogin } = useLoginContext();

  const { request: requestClassInquires } = useCommonQuery({
    query: queries.inquires,
    params: {
      class_seq: classSeq,
      page_per_count: 10,
      current_page: paginationInput.page,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          // console.log(data.result_data);
          paginationInput.updateTotalCount(data.result_data.total_count);
          setInquires(
            data.result_data.data.map((item, index) => ({
              ...item,
              index:
                data.result_data.total_count -
                (paginationInput.page - 1) * 10 -
                index -
                1,
            }))
          );
        } else {
          console.log(data.result_message);
          //   showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        //   showAlert('알림', '스케줄 조회에 실패했습니다.');
      },
    },
    initEnabled: classSeq !== undefined,
  });

  const { request: requestClassInquiresByTeacher } = useCommonQuery({
    query: queries.teacherInquires,
    params: {
      teacher_seq: teacherSeq,
      page_per_count: 10,
      current_page: paginationInput.page,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          // console.log(data.result_data);
          paginationInput.updateTotalCount(data.result_data.total_count);
          setInquires(
            data.result_data.data.map((item, index) => ({
              ...item,
              index:
                paginationInput.totalCount -
                (paginationInput.page - 1) * 10 -
                index -
                1,
            }))
          );
        } else {
          console.log(data.result_message);
          //   showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        //   showAlert('알림', '스케줄 조회에 실패했습니다.');
      },
    },
    initEnabled: teacherSeq !== undefined,
  });

  const { request: requestTogetherOrders } = useCommonQuery({
    query: queries.togetherInquiryOrderList,
    initEnabled: isLogin ? true : false,
    params: {
      class_seq: isLogin ? classSeq : "",
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          setOrderUser(data.result_data);
        } else {
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {},
    },
  });

  const { requestAsync: requestRegistInquiry } = useCommonMutation({
    query: queries.addClassInquire,
    callbacks: {
      onSuccess: (data, variables) => {
        if (data.result_code === "0000") {
          // console.log(data.result_data);
          requestClassInquires();
        } else {
          console.log(data.result_message);
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        showAlert("알림", "문의사항 등록에 실패했습니다.");
      },
    },
  });

  const { request: requestUpdateInquiry } = useCommonMutation({
    query: queries.updateInquiry,
    callbacks: {
      onSuccess: (data, variables) => {
        if (data.result_code === "0000") {
          // console.log(data.result_data, variables);
          setInquires((prev) => {
            const updateItem = prev.find(
              (item) => item.inquiry.inquiry_seq === variables.inquiry_seq
            );
            if (updateItem) {
              updateItem.inquiry.subject = variables.subject;
              updateItem.question.content = variables.content;
            }
            return prev.map((item) =>
              item.inquiry.inquiry_seq === updateItem.inquiry_seq
                ? updateItem
                : item
            );
          });
        } else {
          console.log(data.result_message);
          showAlert("알림", data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        showAlert("알림", "문의사항 수정에 실패했습니다.");
      },
    },
  });

  const { requestAsync: requestDeleteInquiry } = useCommonMutation({
    query: queries.deleteMyInquire,
    callbacks: {
      onSuccess: (data, variables) => {
        if (data.result_code === "0000") {
          // console.log(data.result_data, variables);
          // console.log(inquires);

          if (classSeq) {
            requestClassInquires();
          } else if (teacherSeq) {
            requestClassInquiresByTeacher();
          }
          //   setInquires((prev) =>
          //     prev.filter(
          //       (data) => data.inquiry.inquiry_seq !== variables.inquiry_seq,
          //     ),
          //   );
        } else {
          console.log(data.result_message);
          //   showAlert('알림', data.result_message);
        }
      },
      onError: (error) => {
        // console.log(error);
        //   showAlert('알림', '스케줄 조회에 실패했습니다.');
      },
    },
  });

  useEffect(() => {
    if (paginationInput.ready) {
      if (classSeq) {
        requestClassInquires();
        // requestTogetherOrders();
      } else if (teacherSeq) {
        requestClassInquiresByTeacher();
      }
    }
  }, [paginationInput.page, paginationInput.ready, classSeq, teacherSeq]);

  return {
    inquires,
    orderUser,
    paginationInput,
    requestRegistInquiry,
    requestUpdateInquiry,
    requestDeleteInquiry,
  };
}

export default useClassInquiry;
