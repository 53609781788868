import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Desktop, TabletAndMobile } from 'components/utils/MediaQuery';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Collapse from '@mui/material/Collapse';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';

import { Typography, Grid, FormControl } from '@material-ui/core';

import { useComboBox, useLoginPopup, useRadioGroup } from 'common/hooks';
import { RECEPTION_STATUS } from 'common/constants';

import 'assets/css/components/searchBar.scss'
import "assets/css/together/togetherClassDetail.scss";

import { heart_off_black, heart_on } from 'assets/images/icon';
import usePopAlert from 'components/popAlert/hooks/usePopAlert';
import useModalWrapper from 'components/modal/hooks/useModalWrapper';
import useClassFavorite from './hooks/useClassFavorite';

import { PriceInfoItem, SelectBox, TagList } from 'components/items';
import SeeMoreTeacherInfo from './SeeMoreTeacherInfo';
import { Alert, FormControlLabel, IconButton } from '@mui/material';
import useTogetherSchedule from './hooks/useTogetherSchedule';
import { ModalType1, ModalWrap } from 'components/modal';
import { toCurrency } from 'common/utils';


// TogetherClassInfo.js

function TogetherClassInfo({ 
  keyword = '', 
  hideOption = false, 
  seachbarHandles = undefined,
  data, 
  handleApplyClass
}) {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { checkLogin } = useLoginPopup();
  const { showAlert, showConfirm } = usePopAlert();
  const { favoriteCount, favoriteYn, onAddFavorite, updateClassInfo } = useClassFavorite(data.class_seq, data.favorite_count, data.favorite_yn);
  const scheduleModal = useModalWrapper();
  const shareModalHandler = useModalWrapper();
  const shareData = useMemo(() => {
    return {
      title: data.class_name,
      description: data.class_description,
      imageUrl: data.thumbnail_image_url,
    };
  }, [data]);
    
  let gradeOption = [];
  if(window.location.origin.includes('dev')) {
    gradeOption = [
      { value: 704, name: '예비 초등'},
      { value: 705, name: '초등 1학년'},
      { value: 706, name: '초등 2학년'},
      { value: 707, name: '초등 3학년'},
      { value: 708, name: '초등 4학년'},
      { value: 709, name: '초등 5학년'},
      { value: 710, name: '초등 6학년'}
    ];
  } else {
    if(data.together_class_month === 1) {
      gradeOption = [
        { value: 1125, name: '예비 초등'},
        { value: 1126, name: '초등 1학년'},
        { value: 1127, name: '초등 2학년'},
        { value: 1128, name: '초등 3학년'},
        { value: 1129, name: '초등 4학년'},
        { value: 1130, name: '초등 5학년'},
        { value: 1131, name: '초등 6학년'}
      ];
    } else if(data.together_class_month === 3) {
      gradeOption = [
        { value: 1132, name: '예비 초등'},
        { value: 1133, name: '초등 1학년'},
        { value: 1134, name: '초등 2학년'},
        { value: 1135, name: '초등 3학년'},
        { value: 1136, name: '초등 4학년'},
        { value: 1137, name: '초등 5학년'},
        { value: 1138, name: '초등 6학년'}
      ];
    } else if(data.together_class_month === 6) {
      gradeOption = [
        { value: 1139, name: '예비 초등'},
        { value: 1140, name: '초등 1학년'},
        { value: 1141, name: '초등 2학년'},
        { value: 1142, name: '초등 3학년'},
        { value: 1143, name: '초등 4학년'},
        { value: 1144, name: '초등 5학년'},
        { value: 1145, name: '초등 6학년'}
      ];
    } else if(data.together_class_month === 12) {
      gradeOption = [
        { value: 1315, name: '예비 초등'},
        { value: 1316, name: '초등 1학년'},
        { value: 1317, name: '초등 2학년'},
        { value: 1318, name: '초등 3학년'},
        { value: 1319, name: '초등 4학년'},
        { value: 1320, name: '초등 5학년'}
      ];
    }else {
      gradeOption = [];
    }
  }

  const gradeState = useComboBox(id ? id : -1);
  const dayScheduleSelect = useRadioGroup(-1);
  const [applyDaySelect, setDaySelect] = useState('');

  useEffect(() => {
    if (!data) {
      return;
    }
    updateClassInfo(data.class_seq, data.favorite_count, data.favorite_yn);
  }, [data, updateClassInfo]);

  const onEnrollClass = useCallback(() => {
    if (checkLogin()) {
      return;
    }

    if (data?.reception_status !== RECEPTION_STATUS.opened.value) {
      return;
    }

    handleApplyClass(applyDaySelect, dayScheduleSelect.state, data.schedules[0].schedule_seq);
  }, [checkLogin, data?.reception_status, handleApplyClass]);

  const onMobileEnrollClass = useCallback((e) => {
      if (checkLogin()) {
        return;
      }

      /* if (data?.reception_status !== RECEPTION_STATUS.opened.value) {
        return;
      } */

      scheduleModal.handleOpen(e);
  },[checkLogin, data?.reception_status, scheduleModal]);

  // 2022-08-06 모바일 - 클래스신청하기 팝업 열린 후 일정 선택 알럿 높이 만큼 여백 필요 하여 정의 함
  const [scheduleAlertHeight, setScheduleAlertHeight] = useState(0);
  const scheduleAlertRef = useCallback((scheduleAlertNode) => {
    if (!scheduleAlertNode) {
      setScheduleAlertHeight(0);
    } else {
      setScheduleAlertHeight(scheduleAlertNode.getBoundingClientRect().height);
    }
  });

  const HandleGradeChange = useCallback((e) => {

    // if(e.target.value === 1125 || e.target.value === 1132 || e.target.value === 1139
    //   //  || e.target.value === 1131 || e.target.value === 1138 || e.target.value === 1145
    //    ) {
    //     showConfirm(`알림`, `
    //       해당 학년에 운영 중인 수업이 없습니다.
    //       예비 초등은 6월 이후 오픈됩니다.
    //     `);
    //     return;
    if(e.target.value === 704 || e.target.value === 1126 || e.target.value === 1133 || e.target.value === 1140 || e.target.value === 1316) {
      navigate(`/togetherClass/${e.target.value}`);
      gradeState.select(e.target.value);
    } else {
      navigate(`/togetherClass/${e.target.value}/hidden`);
      gradeState.select(e.target.value);
    }
    // together_class_month
  },[gradeState.state]);

  const changeApplyDaySelect = useCallback((day) => {
    if (!day || applyDaySelect == day) {
      return;
    }
    setDaySelect(day);
    dayScheduleSelect.update(-1);
  }, [applyDaySelect]);

  const { scheduleList } = useTogetherSchedule({
    courseCode: data.together_course_code, 
    gradeCode: data.grade_code,
    applyDaySelect: applyDaySelect
  });

  const submitBtn = useCallback(() => {
    if (dayScheduleSelect.state == -1) {
      showAlert('알림', '일정을 선택해 주세요.');
      return;
    }


    let togetherSchedule = scheduleList?.find((item) => item.schoolHourCode === dayScheduleSelect.state);
       
    handleApplyClass(
      applyDaySelect, 
      dayScheduleSelect.state, 
      data.schedules[0].schedule_seq,
      togetherSchedule
    );
  }, [dayScheduleSelect, applyDaySelect, handleApplyClass]);

  return (
    <Grid className="togetherClassInfo">
      <Grid
        container
        className="class-info-top"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography className="category-name" sx={{ pr: 2 }}>
            {data.category_name}
          </Typography>
          <TagList data={data} />
        </Box>
        <div className="btnShare">
          <ShareIcon onClick={shareModalHandler.handleOpen} />
        </div>
        {shareModalHandler.visibleModal && (
          <ModalShare data={shareData} handleClose={shareModalHandler.handleClose} />
        )}
      </Grid>

      <Grid className="class-info-middle">
        <Typography className="class-title" variant="h3">{data.class_name}</Typography>
        <ul className="class-hashtag">
          {data.keywords && data.keywords.map((keyword, index) => (
              <li key={index}>{keyword}</li>
          ))}
        </ul>

        <div className="class-price-wrap">
          <Typography variant="h3" className="font-kr-bold c-sc4 fs16">
            판매가
          </Typography>
          <div className="info-price desk-info-price">
            <PriceInfoItem data={data} />
          </div>
        </div>
        <ul className="ref-txt-list">
          {data.worksheet_yn && ( <li>* 교재비 포함</li> )}
          {data.bookclub_point_max_rate && data.bookclub_point_max_rate > 0 ? (
            <li>* 클럽 포인트 {data.bookclub_point_max_rate}% 사용 가능</li>
          ) : ('')}
          {data.club_mileage_max_rate && data.club_mileage_max_rate > 0 ? (
            <li>* 클럽 마일리지 {data.club_mileage_max_rate}% 사용 가능</li>
          ) : ('')}
        </ul>

        <SeeMoreTeacherInfo data={data.teacher_info} />
      </Grid>

      <Grid className="class-info-bottom">
        <Desktop>
          <Grid className="together-info-regist">
            {data.together_course_code === "DSKO" || data.together_course_code === "DSTT" ? (
              <></>
            ):( 
              <Grid className="regist-area">
                <Typography className="guide-tit grade">학년을 선택해 주세요.</Typography>
                <FormControl className="gradeSelect">
                  <SelectBox
                    style={{}}
                    options={gradeOption}
                    value={gradeState.state}
                    handleChange={(e) => {
                      HandleGradeChange(e);
                    }}
                  />
                </FormControl>
              </Grid>
            )}
              <Grid className="schedule-area">
                <div className="top-area">
                  <Typography className="guide-tit cal">수업 일정을 선택해 주세요.</Typography>
                  <Typography className="ps-txt" style={{ textAlign: "left" }}>
                    ※ 투게더는 매월 첫 주 기준으로 수업이 시작됩니다. <br />
                    ※ 수업 확정일 전까지 입회 취소/변경에 따라서 신청 가능 정원에 변동이 생길 수 있습니다.
                  </Typography>
                </div>
                <Stack direction="row" spacing={1} className="day-btn-area">
                  <Button className={ applyDaySelect == 'DAY2'? 'active': '' } variant="outlined" onClick={() => {changeApplyDaySelect('DAY2')}}>월요일</Button>
                  <Button className={ applyDaySelect == 'DAY3'? 'active': '' } variant="outlined" onClick={() => {changeApplyDaySelect('DAY3')}}>화요일</Button>
                  <Button className={ applyDaySelect == 'DAY4'? 'active': '' } variant="outlined" onClick={() => {changeApplyDaySelect('DAY4')}}>수요일</Button>
                  <Button className={ applyDaySelect == 'DAY5'? 'active': '' } variant="outlined" onClick={() => {changeApplyDaySelect('DAY5')}}>목요일</Button>
                  <Button className={ applyDaySelect == 'DAY6'? 'active': '' } variant="outlined" onClick={() => {changeApplyDaySelect('DAY6')}}>금요일</Button>
                </Stack>
                {applyDaySelect !== '' && (
                  <Grid className="slt-area">
                    <Stack direction="row" className="thead">
                      <div className="cell">선택</div>
                      <div className="cell">시간표</div>
                      <div className="cell">정원</div>
                      <div className="cell">신청</div>
                    </Stack>

                    <Stack direction="column" className="tbody">
                      {/* <FormControl>
                        <RadioGroup 
                          name="scheduleTime" 
                          value={dayScheduleSelect.state}
                          onChange={dayScheduleSelect.handleChange}
                        >
                          {scheduleList && scheduleList.map((item, idx) =>
                            <Button key={idx} direction="row" className={`row ${!item.acceptTag ? "disabled" : ""}`}>
                              <div className="cell">
                                {item.acceptTag ?
                                  (<FormControlLabel 
                                    value={item.schoolHourCode} 
                                    control={<Radio />} 
                                    checked={dayScheduleSelect.state != -1 && item.schoolHourCode == dayScheduleSelect.state} 
                                  />) : (<></>)}</div>
                              <div className="cell">{item.note}</div>
                              <div className="cell">{item.lectureCnt}/{item.capacity}</div>
                              <div className="cell">{item.acceptTag ? "가능" : "마감"}</div>
                            </Button>    
                          )}
                        </RadioGroup>
                      </FormControl> */}
                        {/* <FormControl>
                          <RadioGroup 
                            name="scheduleTime" 
                            value={dayScheduleSelect.state}
                            onChange={dayScheduleSelect.handleChange}
                          >
                            {scheduleList && scheduleList.map((item, idx) =>
                              <FormControlLabel 
                                direction="row"
                                value={item.schoolHourCode} 
                                control={<Radio />} 
                                checked={dayScheduleSelect.state != -1 && item.schoolHourCode == dayScheduleSelect.state} 
                                label={`${item.note} ${item.lectureCnt}/${item.capacity} ${item.acceptTag ? "가능" : "마감"}`}
                              />
                            )}
                          </RadioGroup>
                      </FormControl> */}
                      <FormControl>
                        <RadioGroup 
                          name="scheduleTime" 
                          value={dayScheduleSelect.state}
                          onChange={dayScheduleSelect.handleChange}
                        >
                          {scheduleList && scheduleList.length > 0 ? (
                            scheduleList?.map((item, idx) =>
                              <FormControlLabel 
                                key={idx}
                                disabled={item.isPossible === "N" ? true : false}
                                className={`${dayScheduleSelect.state != -1 && item.schoolHourCode == dayScheduleSelect.state ? "isChecked" : ""} ${item.isPossible === "N" && "disabled" }`}
                                value={item.schoolHourCode} 
                                control={<Radio />} 
                                checked={dayScheduleSelect.state != -1 && item.schoolHourCode == dayScheduleSelect.state} 
                                label={<>
                                  <div className="cell">{item.note}</div>
                                  <div className="cell">{item.lectureCnt}/{item.lectureCnt > item.capacity ? item.lectureCnt : item.capacity}</div>
                                  <div className="cell">{item.isPossible === "Y" ? "가능" : "마감"}</div>
                                </>}
                              />   
                            )
                          ) : (
                            <div style={{ textAlign: "center", margin: "50px 0", fontWeight: "bold", color: "#1c1c1c" }}>신청 가능한 일정이 없습니다.</div>
                          )}
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <Stack direction="row" spacing={2} className="btn-area">
                  <Button className="favorite-count-icon" onClick={onAddFavorite}>
                    <img src={favoriteYn ? heart_on : heart_off_black} alt="하트 이미지" />
                    <span className="num">{favoriteCount}</span>
                  </Button>
                  <Button 
                    className={`schedule-slt-btn`} // ${data?.reception_status !== RECEPTION_STATUS.opened.value ? 'disabled-btn' : ''}
                    variant="contained" 
                    onClick={submitBtn}
                  >다음 달 일정 선택 완료</Button>
              </Stack>
              <Stack direction="column" className="txt-area">
                <Typography className="ps-txt" style={{display: data.grade_code ? "none" : ""}}>※ 학년을 선택해 주세요.</Typography>
                <Typography className="ps-txt" style={{display: dayScheduleSelect.state !== -1 ? "none" : ""}}>※ 수업 일정을 선택해 주세요.</Typography>
              </Stack>
            </Grid>
        </Desktop>

        <TabletAndMobile>
          <Grid className="together-info-regist">
            <Stack direction="row" spacing={2} className="btn-area">
              <Button className="favorite-count-icon" onClick={onAddFavorite}>
                <img src={favoriteYn ? heart_on : heart_off_black} alt="하트 이미지" />
                <span className="num">{favoriteCount}</span>
              </Button>
              <Button 
                className={`schedule-slt-btn`} // ${data?.reception_status !== RECEPTION_STATUS.opened.value ? 'disabled-btn' : ''}
                variant="contained" 
                onClick={onMobileEnrollClass}
              >다음 달 수업 일정 선택</Button>
            </Stack>
            {scheduleModal.visibleModal && (
                <ModalWrap
                  className="option-change-btn"
                  modalinfo="other-button"
                  visible={scheduleModal.visibleModal}
                  handleOpen={scheduleModal.handleOpen}
                  handleClose={scheduleModal.handleClose}
                >
                    <ModalType1
                      title="다음 달 수업 일정 선택"
                    >
                        <Grid className="togetherClassInfo">
                          <Grid className="together-info-regist">
                          {data.together_course_code === "DSKO" || data.together_course_code === "DSTT" ? (
                            <></>
                          ):( 
                            <Grid className="together-regist-area">
                              <Typography className="guide-tit grade">학년을 선택해 주세요.</Typography>
                              <FormControl className="gradeSelect">
                                  <SelectBox
                                      options={gradeOption}
                                      value={gradeState.state}
                                      handleChange={(e) => {
                                          HandleGradeChange(e);
                                      }}
                                  />
                              </FormControl>
                            </Grid>
                          )}
                            <Grid className="together-schedule-area">
                              <div className="top-area">
                                <Typography className="guide-tit cal">수업 일정을 선택해 주세요.</Typography>
                                <Typography className="ps-txt" style={{ textAlign: "left"}}>
                                  ※ 투게더는 매월 첫 주 기준으로 수업이 시작됩니다. <br />
                                  ※ 수업 확정일 전까지 입회 취소/변경에 따라서 신청 가능 정원에 변동이 생길 수 있습니다.
                                </Typography>
                              </div>
                              <Stack direction="row" spacing={1} className="day-btn-area">
                                <Button className={ applyDaySelect == 'DAY2'? 'active': '' } variant="outlined" onClick={() => {changeApplyDaySelect('DAY2')}}>월요일</Button>
                                <Button className={ applyDaySelect == 'DAY3'? 'active': '' } variant="outlined" onClick={() => {changeApplyDaySelect('DAY3')}}>화요일</Button>
                                <Button className={ applyDaySelect == 'DAY4'? 'active': '' } variant="outlined" onClick={() => {changeApplyDaySelect('DAY4')}}>수요일</Button>
                                <Button className={ applyDaySelect == 'DAY5'? 'active': '' } variant="outlined" onClick={() => {changeApplyDaySelect('DAY5')}}>목요일</Button>
                                <Button className={ applyDaySelect == 'DAY6'? 'active': '' } variant="outlined" onClick={() => {changeApplyDaySelect('DAY6')}}>금요일</Button>
                              </Stack>
                              {applyDaySelect !== '' && (
                                <Grid className="slt-area">
                                  <Stack direction="row" className="thead">
                                    <div className="cell">선택</div>
                                    <div className="cell">시간표</div>
                                    <div className="cell">정원</div>
                                    <div className="cell">신청</div>
                                  </Stack>
                                  <Stack direction="column" className="tbody">
                                    <FormControl>
                                      <RadioGroup 
                                        name="scheduleTime" 
                                        value={dayScheduleSelect.state}
                                        onChange={dayScheduleSelect.handleChange}
                                      >
                                        {scheduleList && scheduleList?.map((item, idx) =>
                                          <FormControlLabel 
                                            key={idx}
                                            disabled={item.isPossible === "N" ? true : false}
                                            className={`${dayScheduleSelect.state != -1 && item.schoolHourCode == dayScheduleSelect.state ? "isChecked" : ""} ${item.isPossible === "N" && "disabled" }`}
                                            value={item.schoolHourCode} 
                                            control={<Radio />} 
                                            checked={dayScheduleSelect.state != -1 && item.schoolHourCode == dayScheduleSelect.state} 
                                            label={<>
                                              <div className="cell">{item.note}</div>
                                              <div className="cell">{item.lectureCnt}/{item.lectureCnt > item.capacity ? item.lectureCnt : item.capacity}</div>
                                              <div className="cell">{item.isPossible === "Y" ? "가능" : "마감"}</div>
                                            </>}
                                          />   
                                        )}
                                      </RadioGroup>
                                    </FormControl>
                                  </Stack>
                                </Grid>
                              )}
                            </Grid>
                            {dayScheduleSelect.state !== -1 && (
                            <>
                              <Grid className="together-check-area" ref={scheduleAlertRef}>
                                {/* <Typography className="guide-tit cal">수업 일정 확인</Typography> */}
                                <Collapse in={true} className="schedule-alert">
                                  {/* <Alert
                                    icon={false}
                                    className="mobile-alert"
                                    action={
                                      <IconButton aria-label="close" color="inherit" size="small" onClick={() => {}}>
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    }
                                  >
                                    <Typography>{`수업 시작일: ${data.classScheduleInfo}`}</Typography>
                                  </Alert> */}
                                  <div className="total-price-wrap">
                                    <Typography>총 결제 금액</Typography>
                                    <PriceInfoItem data={data} />
                                  </div>
                                </Collapse>
                                <Stack direction="row" spacing={2} className="class-btnWrap">
                                  <Button className="favorite-count-icon" onClick={onAddFavorite}>
                                    <img src={favoriteYn ? heart_on : heart_off_black} alt="하트 이미지" />
                                    <span className="num">{favoriteCount}</span>
                                  </Button>
                                  <Button className="register-class-btn" variant="contained" onClick={submitBtn}>투게더 클래스 신청하기</Button>
                                </Stack>
                              </Grid>
                            </>
                          )}
                          </Grid>
                        </Grid>
                    </ModalType1>
                </ModalWrap>
              )}
          </Grid>

        </TabletAndMobile>
      </Grid>
    </Grid>
  );
}

export default React.memo(TogetherClassInfo);
