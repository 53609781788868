import { METHOD } from 'common/hooks';

export const togetherApi = (requestFunc) => {
    return {
        togetherClasses: {
            key: 'togetherClasses', // 투게더 클래스 전체 조회
            queryFn: (params) => requestFunc(METHOD.GET, '/together/main/best', params),
        },
        togetherCategoryClasses: {    // 카테고리에 따른 투게더 클래스 조회
            key: 'togetherCategoryClasses', 
            queryFn: (params) => requestFunc(METHOD.GET, `/together/main/best/${params.category_seq}/classes`, params),
        },
        togetherClassInfo: {
            key: 'togetherClassInfo', // 투게더 클래스 상세 조회
            queryFn: (params) => requestFunc(METHOD.GET, `/together/classes/${params.class_seq}`),
        },
        togetherSecretClassInfo: {
            key: 'togetherSecretClassInfo', // 투게더 Hidden 클래스  조회
            queryFn: (params) => requestFunc(METHOD.GET, `/together/classes/${params.class_seq}/hidden`),
        },
        togetherApplyClass: {
            key: 'togetherApplyClass', // 투게더 수업 신청
            queryFn: (params) => requestFunc(METHOD.POST, `/together/classes/${params.class_seq}/apply`, params),
        },
        togetherChangeOrderSchedule: {
            key: 'togetherChangeOrderSchedule', // 투게더 수업 신청 시, 수업 시작일 변경
            queryFn: (params) => requestFunc(METHOD.GET, `/together/schedule/first/study`, params),
        },
        togetherCheckDuplicatedTime: {
            key: 'togetherCheckDuplicatedTime', // 투게더 동시간대 수업 여부 조회 Check > 신청 시, 조회
            queryFn: (params) => requestFunc(METHOD.POST, `/together/classes/checkIsDuplicatedTime`, params),
        },
        togetherSchedules: {
            key: 'togetherSchedules',   // 투게더 클래스 스케줄 조회 ( 입회 )
            queryFn: (params) => requestFunc(METHOD.GET, `/together/schedule/capacity`, params),
        },
        togetherChangeSchedules: {
            key: 'togetherChangeSchedules',   // 투게더 클래스 스케줄 조회 ( 변경 가능 )
            queryFn: (params) => requestFunc(METHOD.GET, `/together/schedule/changeable`, params),
        },
        togetherSchedulesChangeApply: {
            key: 'togetherSchedulesChangeApply',   // 투게더 클래스 스케줄 변경
            queryFn: (params) => requestFunc(METHOD.POST, `/together/schedule/change`, params),
        },
        togetherOrder: {
            key: 'togetherOrder', // 투게더 수업 결제
            queryFn: (params) => requestFunc(METHOD.POST, `/together/orders`, params),
        },
        togetherCompleteOrder: {
            key: 'togetherCompleteOrder', // 투게더 수업 결제 완료
            queryFn: (params) => requestFunc(METHOD.POST, `/together/orders/payment`, params),
        },
        togetherProfileEdit: {
            key: 'togetherProfileEdit', // 마이페이지 > 프로필 명 수정
            queryFn: (params) => requestFunc(METHOD.POST, `/mypage/orders/${params.order_seq}/profile`, params),
        },
        togetherInquiryOrderList: {
            key: 'togetherInquiryOrderList', // 클래스 상세 > 문의하기 > 결제정보
            queryFn: (params) => requestFunc(METHOD.GET, `/together/classes/${params.class_seq}/orders`, params),
        },
        togetherCancelOrder: {
            key: 'togetherCancelOrder', // 수업 신청 - 주문 결제 취소
            queryFn: (params) => requestFunc(METHOD.POST, `/together/orders/${params.order_seq}/payment-cancel`, params),
        },
        togetherChkAuthorization: {
            key: 'togetherChkAuthorization', // All교과 | 슈퍼팟 이용권한 조회
            queryFn: (params) => requestFunc(METHOD.POST, `/together/classes/superpot/history`, params),
        },
    };
};
