import { useState, useCallback, useEffect } from "react";
import { useCommonMutation, useCommonQuery } from "common/hooks";
import { useApiContext } from "common/context";
import usePopAlert from "components/popAlert/hooks/usePopAlert";
import useModalWrapper from "components/modal/hooks/useModalWrapper";
import { DOMAIN } from "common/constants";

function useBigCalendarClassItem(classInfo) {
  const { queries } = useApiContext();
  const { showAlert, showConfirm } = usePopAlert();
  const materialsModal = useModalWrapper();
  const [materials, setMaterials] = useState([]);
  const [fileInfo, setFileInfo] = useState(null);
  const [profileName, setProfileName] = useState("");

  const { request: requestEnableClassroom } = useCommonQuery({
    query: queries.enableClassroom,
    params: {
      classroom_seq: classInfo?.classroom_seq,
      classmate_seq: classInfo?.classmate_seq,
      timetable_seq: classInfo?.timetable_seq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          showConfirm("알림", "수업에 입장하시겠습니까?", {
            confirmHandler: () =>
              window.open(
                `${DOMAIN}/room/u/${data.result_data.classroom_seq}/${data.result_data.classmate_seq}?accessToken=${data.result_data.token}&ts=${data.result_data.timetable_seq}`,
                "_blank"
              ),
          });
        } else {
          console.log(data.result_message);
          showAlert("알림", data.result_message);
        }
      },
      onError: () => {
        showAlert("알림", "수업 입장에 실패했습니다.");
      },
    },
    initEnabled: false,
  });

  const { request: requestMetarials } = useCommonQuery({
    query: queries.classMaterials,
    params: {
      class_seq: classInfo?.class_seq,
      classroom_seq: classInfo?.classroom_seq,
    },
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          setMaterials(data.result_data);
          materialsModal.handleOpen();
        } else {
          showAlert("알림", data.result_message);
          setMaterials([]);
        }
      },
      onError: () => {
        showAlert("알림", "수업자료 조회에 실패했습니다.");
        setMaterials([]);
      },
    },
    initEnabled: false,
  });

  const { request: requestDownloadMaterial } = useCommonQuery({
    query: queries.downloadClassMaterial,
    params: {
      class_seq: classInfo?.class_seq,
      file_seq: fileInfo?.material_file_seq,
      classroom_seq: classInfo?.classroom_seq,
    },
    callbacks: {
      onSuccess: (data) => {
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileInfo.file_original_name);
        link.click();
        URL.revokeObjectURL(url);
        setFileInfo(null);
      },
      onError: () => {
        setFileInfo(null);
        showAlert("알림", "다운로드에 실패했습니다.");
      },
    },
    initEnabled: false,
  });

  const { request: requestClassroom } = useCommonQuery({
    query: queries.enterClassroom,
    params: {
      classroom_seq: classInfo?.classroom_seq,
      classmate_seq: classInfo?.classmate_seq,
      timetable_seq: classInfo?.timetable_seq,
    },
    callbacks: {
      onSuccess: (data) => {
        console.log("enterclassroom이 어디서 실행되고 있는가..111");
        console.log("data :: ", data.result_code);
      },
      onError: (error) => {},
    },
    initEnabled: false,
  });

  /* 투게더용 프로필명 변경 */
  const { request: requestProfileEdit } = useCommonMutation({
    query: queries.togetherProfileEdit,
    callbacks: {
      onSuccess: (data) => {
        console.log("data :: ", data.result_code);
      },
      onError: (error) => {
        console.log("error :: ", error);
      },
    },
  });

  /* 투게더용 스케줄 변경 */
  const { request: requestScheduleChange } = useCommonMutation({
    query: queries.togetherSchedulesChangeApply,
    callbacks: {
      onSuccess: (data) => {
        if (data.result_code === "0000") {
          if (data.result_data.resultCode === "200") {
            showAlert("알림", data.result_data.resultMsg);
          } else {
            showAlert("알림", data.result_data.resultMsg);
          }
        }
      },
      onError: (error) => {
        console.log("error :: ", error);
      },
    },
  });

  useEffect(() => {
    if (fileInfo) requestDownloadMaterial();
  }, [fileInfo]);

  const handleDownloadClick = useCallback(() => {
    setMaterials([]);
    setFileInfo(null);
    requestMetarials();
  }, []);

  const handleMaterialClick = useCallback((fileInfo) => {
    setFileInfo(fileInfo);
  }, []);

  const handleEnterClick = useCallback(
    async (buttons) => {
      const { data } = await requestClassroom();
      if (!data?.result_data.lassroom_seq) return;
      const classUrl = data?.result_data.modified_url || "일반클래스";

      if (buttons?.enter_room_yn) {
        if (!classInfo?.classroom_seq || !classInfo?.classmate_seq)
          showAlert("알림", "수업에 입장할 수 없습니다.");

        if (classUrl === "일반클래스") requestEnableClassroom();
        else
          showConfirm("알림", "수업에 입장하시겠습니까?", {
            confirmHandler: () => window.open(classUrl, "_blank"),
          });
      }

      console.log("[class url] ", classUrl);
    },
    [
      classInfo?.classmate_seq,
      classInfo?.classroom_seq,
      classInfo?.class_seq,
      requestEnableClassroom,
      showAlert,
    ]
  );

  const applyProfileEdit = useCallback((name, orderSeq) => {
    setProfileName(name);
    requestProfileEdit({ order_seq: orderSeq, bookclubProfileName: name });
  });

  const applyScheduleEdit = useCallback(
    (lectureSeq, asis, tobe, change_type) => {
      requestScheduleChange({
        lecture_seq: lectureSeq,
        asis_study_room_seq: asis,
        tobe_study_room_seq: tobe,
        change_type: change_type,
      });
    }
  );

  return {
    materials,
    materialsModal,
    handleDownloadClick,
    handleMaterialClick,
    handleEnterClick,
    applyProfileEdit,
    applyScheduleEdit,
  };
}

export default useBigCalendarClassItem;
